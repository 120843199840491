.backdrop-blur {
  background: #ffffff75;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); }

.corporate-top-padding {
  padding-top: 70px !important; }

.card-body img {
  max-width: 100%; }

.card-body iframe {
  max-width: 100%; }

.card-body .news-big-text-block {
  overflow: auto; }

@media screen and (max-width: 768px) {
  .corporate-top-padding {
    padding-top: 55px !important; } }

@media (min-width: 992px) {
  .header .header-top {
    background: #222222; } }

.header-top__logo {
  max-height: 23px; }

.tool-header-top__logo-habwin {
  max-height: 23px !important; }

.tool-header-top__logo-partyrakeback {
  max-height: 50px !important; }

.tool-header-top__logo-mobile-habwin {
  max-height: 30px !important; }

.tool-header-top__logo-mobile-partyrakeback {
  max-height: 50px !important; }

.tool-header-top__logo-mobile-easyamericanpoker {
  max-height: 50px !important; }

.flags-dropdown__flag-btn {
  background: transparent;
  border: transparent; }

.flags-dropdown__flag-img {
  border: 2px #222222;
  border-radius: 6px;
  width: 22px;
  height: auto; }

.menu-item-active .menu-link {
  background-color: #DAF4E8 !important; }

.menu-item-active .menu-text {
  color: #01A35A !important; }

.inverted-corner:after {
  bottom: 0;
  content: '';
  position: absolute;
  right: -29px;
  height: 30px;
  width: 30px;
  border-bottom-left-radius: 30%;
  background: transparent;
  box-shadow: -10px 0px 0 0 white; }

.inverted-corner:before {
  bottom: 0;
  content: '';
  position: absolute;
  left: -29px;
  height: 30px;
  width: 30px;
  border-bottom-right-radius: 30%;
  background: transparent;
  box-shadow: 10px 0 0 0 white; }

.header-signin {
  font-weight: 550 !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #d2d2d2 !important; }

.header-signin:hover {
  color: white !important; }

.header__nav-link {
  transition: 0s !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  text-align: center !important;
  text-transform: uppercase !important; }

.header__nav-link--selected {
  color: #464E5F !important; }

.header-home {
  height: 70px !important; }

.header-no-subheader {
  height: 70px !important; }

@media (max-width: 768px) {
  .header-home {
    height: 0 !important; }
  .header-no-subheader {
    height: 80px !important; } }

.header-no-subheader-on-base {
  margin-top: -90px !important; }

.corporate-header_topbar {
  z-index: 9999; }

.corporate-header_topbar--mobile {
  background-color: #636363 !important; }

.tool-header_topbar {
  z-index: 3 !important; }

.tool-header_topbar--mobile {
  background-color: #636363 !important; }

.sales-header_topbar {
  z-index: 3 !important; }

.sales-header_topbar--mobile {
  background-color: #636363 !important; }

.corporate-header_sign-in {
  width: 62px; }

.steps {
  margin-top: 25px; }

.steps__background-square {
  height: 257px;
  border-radius: 12px;
  background: #01A35A;
  box-shadow: 0 0 20px rgba(56, 71, 109, 0.03);
  width: 100%; }

.background-square__first-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #DAF4E8;
  margin-top: 24px; }

.background-square__second-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #FFFFFF; }

.background-square__third-text {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  text-align: center;
  color: #212121; }

.steps_bubbles {
  position: absolute;
  width: 172px;
  height: 169px;
  right: -19px;
  top: -22px; }

.steps__steps-row {
  margin-top: -80px; }

.steps-card {
  height: 250px; }

.steps__step-number {
  height: 19px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 19px;
  text-align: center;
  color: #666666; }

.steps__step-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  color: #212121;
  margin: 0 10px; }

.steps__step-subtitle {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #80808F;
  margin: 0 10px; }

.rooms-card {
  margin-top: 30px;
  min-height: 699px; }

.news-card {
  margin-top: 30px;
  min-height: 699px; }

.news-big-image {
  border-radius: 6px;
  height: 170px; }

.news-small-image {
  border-radius: 6px;
  height: 60px;
  width: auto; }

.news-big-text-block {
  text-overflow: ellipsis; }

.news-small-text-block {
  text-overflow: ellipsis;
  overflow: hidden; }

.news-big-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #212121; }

.news-big-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #80808F;
  word-break: break-word; }

.news-small-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F; }

.news-small-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #B5B5C3;
  word-break: break-word; }

.news-card__nav-link {
  color: #464E5F !important; }
  .news-card__nav-link:hover {
    color: #01A35A !important; }

.news-card__nav-link.active {
  background: #DAF4E8 !important;
  color: #01A35A !important; }

.room-bonus {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F; }

.room-logo-container {
  background: #222222;
  border-radius: 6px; }

.home-title-container__title {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  color: #FFFFFF; }

@media only screen and (max-width: 768px) {
  .home-title-container__title {
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 78px;
    color: #FFFFFF; } }

.home-title-button {
  width: 160px !important;
  height: 56px !important;
  background: #01A35A !important;
  border-radius: 6px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  color: white; }

.green-gradient-container {
  background: linear-gradient(180deg, #296A42 -17.6%, #01A35A 40.59%, #9EE2C4 100%);
  transform: matrix(1, 0, 0, -1, 0, 0); }

.steps-background {
  height: 431px !important; }

.home-page-description {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  text-align: center;
  color: #222222; }

.home-time-to-win {
  font-style: normal;
  font-weight: 800;
  font-size: 64px;
  line-height: 96px;
  text-align: center;
  color: #01A35A; }

.home-description-card {
  bottom: -100px !important;
  transform: translateX(-50%);
  left: 50%; }

.steps-container {
  top: -290px !important;
  transform: translateX(-50%);
  left: 50%; }

.room-carousel-card {
  width: 373px;
  background: #FFFFFF;
  border-radius: 16px;
  overflow: hidden; }

@media only screen and (max-width: 768px) {
  .room-carousel-card {
    width: 280px;
    height: 310px;
    background: #FFFFFF;
    border-radius: 16px;
    overflow: hidden; } }

.room-carousel-card-header {
  background: #222222; }

.room-carousel-img {
  width: 151px;
  height: auto;
  min-height: 100px; }

.step-card-header {
  width: auto;
  height: 110px;
  background: #222222; }

@media screen and (max-width: 768px) {
  .step-card-header {
    width: auto;
    height: 100px;
    background: #222222; } }

.step-card-body {
  padding: 1.25rem; }

.step-number {
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 19px;
  text-align: center;
  color: #FFFFFF; }

.step-title {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 33px;
  text-align: center;
  color: #FFFFFF; }

.home-step-description {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  color: #80808F; }

.home-rewards-container {
  height: 550px !important; }

.home-rewards-title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  background: linear-gradient(253.58deg, #FFC000 1.55%, #FF8A00 95.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.home-rewards-description {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF; }

.home-reward-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #FFFFFF; }

@media screen and (max-width: 768px) {
  .home-rewards-title {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    background: linear-gradient(253.58deg, #FFC000 1.55%, #FF8A00 95.8%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .home-rewards-description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF; } }

.rewards-top-padding {
  padding-top: 160px !important; }

.reward-images-top-margin {
  margin-top: 60px !important; }

@media only screen and (max-width: 768px) {
  .rewards-carousel {
    margin-top: -30px; } }

.reward-circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: linear-gradient(253.58deg, #FFC000 1.55%, #FF8A00 95.8%); }

.reward-image {
  height: auto;
  width: 130px; }

.rewards-rectangle-img {
  width: 220px;
  height: 110px;
  position: absolute;
  background: rgba(218, 244, 232, 0.2);
  -webkit-backdrop-filter: blur(15px);
          backdrop-filter: blur(15px);
  border-radius: 6px;
  align-self: flex-end !important; }

.reward-background {
  background: linear-gradient(253.58deg, #FFC000 1.55%, #FF8A00 95.8%);
  border-radius: 50%;
  width: 100px;
  height: 100px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); }

.play-black-box {
  height: 400px;
  background-color: black; }

.play-black-box__title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  color: #FFFFFF; }

@media only screen and (max-width: 768px) {
  .play-black-box__title {
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF; } }

.card-play-box-image {
  right: 0;
  bottom: 0; }

.football-play-box-image {
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%; }

.roulette-play-box-image {
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  width: 100%; }

.play-black-box__btn {
  width: 35%;
  max-height: 25%;
  background: #01A35A;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF; }

@media only screen and (max-width: 768px) {
  .play-black-box__btn {
    width: 139px;
    height: 40px;
    background: #01A35A;
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    align-self: center;
    color: #FFFFFF; } }

.slick-list {
  overflow-y: visible !important; }

.play-black-box__content {
  padding: 60px 60px 80px 60px;
  justify-content: space-between; }

@media screen and (max-width: 768px) {
  .play-black-box__content {
    text-align: center;
    padding: 45px 30px 0px 30px;
    justify-content: unset; } }

.home-rooms-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  background: linear-gradient(253.58deg, #FFC000 1.55%, #FF8A00 95.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; }

.home-rooms-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #FFFFFF; }

@media only screen and (max-width: 768px) {
  .home-rooms-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: center;
    background: linear-gradient(253.58deg, #FFC000 1.55%, #FF8A00 95.8%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; }
  .home-rooms-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #FFFFFF; } }

.home-rooms-title-padding {
  padding: 70px 0; }

.home-room-bonus {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: #3F4254; }

.home-room-recommended {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #3F4254; }

.home-room-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 28px;
  text-align: center;
  color: #80808F; }

.home-room-button {
  width: 139px;
  height: auto;
  background: #EC2F38 !important;
  border-radius: 6px;
  position: absolute;
  transform: translateY(100%); }

@media only screen and (max-width: 768px) {
  .home-room-button {
    width: 200px;
    height: auto;
    background: #EC2F38 !important;
    border-radius: 6px;
    position: absolute;
    transform: translateY(100%); } }

.room-carousel-item {
  opacity: 0.5;
  transition-duration: 0.2s;
  width: 375px;
  height: 345px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px; }
  .room-carousel-item.active {
    opacity: 1; }
  .room-carousel-item:hover {
    opacity: 1; }

.home-rooms-background {
  background: linear-gradient(180deg, #296A42 -17.6%, #01A35A 40.59%, #9EE2C4 100%) no-repeat;
  background-size: 100% 75rem; }

.home-rooms-background-img {
  right: 0;
  position: absolute; }

@media screen and (max-width: 768px) {
  .home-rooms-background {
    background-repeat: no-repeat;
    background-size: 100% 100rem; }
  .home-rooms-background-img {
    width: 100%;
    right: 0;
    position: absolute; } }

.home-advantages-card {
  background: #FFFFFF;
  border-radius: 12px; }

.home-advantages-title {
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #01A35A; }

.home-advantage-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #222222;
  margin-bottom: 30px; }

.home-advantage-description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #80808F; }

.advantage-separator {
  margin-top: 5rem;
  border: 1px solid rgba(69, 88, 128, 0.2);
  width: 100%; }

.home-final-card {
  padding-top: 50px !important; }

.home-final-card__mini-title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #01A35A;
  display: block;
  margin-bottom: 30px; }

.home-final-card__title {
  display: block;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
  color: #212121;
  margin-bottom: 20px; }

.home-final-card__description {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #80808F; }

.gradient-bg-home {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%);
  width: 100%;
  height: 70rem;
  border-radius: 0 0 60% 60%/0 0 5% 5%; }

.background-particles {
  position: absolute;
  display: block;
  z-index: -1; }

.circle {
  border-radius: 35%;
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%);
  width: 100%;
  height: 400px; }

.img-top-home-container {
  width: 100%;
  height: 100%;
  background-position: bottom center;
  position: relative; }

.img-top-home {
  background-position: bottom center;
  position: relative; }

.play-top-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

@media screen and (max-width: 768px) {
  .play-top-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; } }

.win-top-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 72px;
  line-height: 82px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); }

@media screen and (max-width: 768px) {
  .win-top-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 42px;
    line-height: 48px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); } }

.habwin-top-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 64px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

@media screen and (max-width: 768px) {
  .habwin-top-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 64px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; } }

.register-container {
  width: 100%;
  background: #FFFFFF;
  z-index: 1; }

.bets-top-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #222222; }

@media screen and (max-width: 768px) {
  .bets-top-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #222222; } }

.time-top-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  text-transform: uppercase;
  color: #01A35A; }

@media screen and (max-width: 768px) {
  .time-top-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;
    color: #01A35A; } }

.register-card {
  height: 180px;
  background: #FFFFFF;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border-radius: 12px; }

.title-register-card {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 33px;
  color: #01A35A;
  text-transform: uppercase; }

.description-register-card {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 23px;
  color: #666666; }

@media screen and (max-width: 768px) {
  .description-register-card {
    max-width: 60%;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #666666; } }

.room-title-container {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  color: #FFFFFF; }

.promotion-card-img {
  width: 340px;
  height: 180px; }

.promotion-card-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F; }

.promotion-card-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #B5B5C3;
  word-break: break-word; }

.promotion-details-button-card {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #01A35A; }

@media screen and (max-width: 768px) {
  .promotion-card-img {
    width: 282px;
    height: 150px; }
  .promotion-card-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #464E5F; }
  .promotion-card-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #B5B5C3;
    word-break: break-word; }
  .promotion-details-button-card {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    text-align: right;
    color: #01A35A; } }

.gradient-bg-promotions {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%);
  background-size: 100% 70rem;
  background-repeat: no-repeat; }

.background-promotions {
  top: -100px;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: absolute;
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat; }

.wave-background-promotions {
  bottom: 0;
  width: 100%;
  position: absolute; }

.register-habwin-button {
  width: 100%;
  background: #EC2F38 !important;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

.see-rewards-button {
  width: 20%;
  background: #EC2F38 !important;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

.promotion-access-button {
  width: 12%;
  height: auto;
  background: #01A35A;
  border-radius: 6px; }

@media screen and (max-width: 768px) {
  .promotion-access-button {
    width: 120px;
    height: auto;
    background: #01A35A;
    border-radius: 6px; }
  .see-rewards-button {
    width: 60%;
    background: #EC2F38 !important;
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; } }

.begin-button-top {
  padding: 1.5rem;
  background: #EC2F38 !important;
  border-radius: 6px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

@media screen and (max-width: 768px) {
  .begin-button-top {
    padding: 1.5rem;
    background: #EC2F38 !important;
    border-radius: 6px; } }

@media screen and (max-width: 768px) {
  .steps-arrow {
    transform: rotate(90deg); } }

.home-rewards-background {
  background-size: 100% 100%;
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat; }

.swiper-promotions-container {
  max-width: 70%; }

@media screen and (max-width: 768px) {
  .swiper-promotions-container {
    max-width: 100%; } }

.habpoker-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #FFFFFF; }

.habpoker-news-main-image {
  width: 100%;
  min-width: 100px;
  height: auto;
  border-radius: 6px; }

.habpoker-professor-image {
  height: 258px; }

.more-promotions__promotion-title {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F; }

.more-promotions__promotion-description {
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #B5B5C3;
  word-break: break-word; }

.small-room-img {
  border-radius: 6px;
  width: 130px;
  height: auto; }

.small-post-img {
  border-radius: 6px;
  width: 150px;
  height: auto; }

.post-details-button {
  font-family: Poppins;
  padding: 10px 20px;
  background: #01A35A;
  border-radius: 6px; }

@media screen and (max-width: 576px) {
  .post-details-button {
    font-family: Poppins;
    padding: 10px 20px;
    background: #01A35A;
    border-radius: 6px;
    text-align: center; } }

.promotion-details-button {
  font-family: Poppins;
  padding: 10px 20px;
  background: #01A35A;
  border-radius: 6px; }

.room-details-button {
  font-family: Poppins;
  padding: 10px 20px;
  background: #01A35A;
  border-radius: 6px; }

.post-small-title-card {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F; }

.post-small-description-card {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #B5B5C3;
  word-break: break-word; }

.card-promotion-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F; }

.card-promotion-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #B5B5C3;
  word-break: break-word; }

.rooms-card-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 25px;
  color: #01A35A; }

.room-img-card {
  border-radius: 6px;
  width: 100%;
  height: 110px;
  -o-object-position: center center;
     object-position: center center; }

.promotions-card {
  width: 259px; }

.promotions-card-img {
  border-radius: 6px;
  width: 259px;
  height: 142px; }

.room-card {
  background: #F3F6F9;
  border-radius: 16px;
  width: 100%;
  overflow: hidden;
  max-width: 250px; }

@media (max-width: 768px) {
  .room-card {
    background: #F3F6F9;
    border-radius: 16px;
    width: 100%;
    overflow: hidden;
    max-width: 280px; }
  .promotions-card {
    width: 280px; }
  .promotions-card-img {
    border-radius: 6px;
    width: 280px;
    height: 154px; } }

.room-card-header {
  background: #222222;
  height: 100%; }

.post-card {
  background: #F3F6F9;
  border-radius: 16px;
  width: 100%;
  overflow: hidden; }

@media (max-width: 576px) {
  .post-card {
    margin-top: 2rem; } }

.post-small-image-card {
  border-radius: 6px;
  width: 110px;
  height: 60px; }

@media (max-width: 576px) {
  .post-small-image-card {
    border-radius: 6px;
    width: 260px;
    height: 143px; } }

.post-card-header {
  background: #F3F6F9;
  padding: 2rem 2.25rem 0 2.25rem; }

@media (max-width: 576px) {
  .post-card-header {
    display: none; } }

.background-habpoker {
  width: 100%;
  height: 100%;
  background-position: top right; }

.gradient-bg-page {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%);
  background-size: 100% 50rem;
  background-repeat: no-repeat; }

@media screen and (max-width: 576px) {
  .gradient-bg-page {
    background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%);
    background-size: 100% 50rem;
    background-repeat: no-repeat; } }

.rooms-center-cards-container {
  gap: 20px; }

@media screen and (max-width: 768px) {
  .rooms-center-cards-container {
    gap: 0; }
  .swiper-slide {
    height: initial; } }

.footer-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 42px;
  letter-spacing: 0.01em;
  color: #FFFFFF; }

.footer-item {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #01A35A; }

.footer__small-item {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  color: #01A35A; }

.footer__big {
  background: #222222;
  padding: 44px 0; }

.footer__small {
  background: #666666;
  padding: 20px 0; }

.footer__small-container {
  height: 70px; }

.footer__divider {
  background: #666666;
  border-radius: 56px;
  width: 2px; }

.footer__copyright {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #FFFFFF; }

.footer__section {
  padding: 25px 0; }

.about-habwin {
  width: 303px; }

.responsable-flex .img-18-plus {
  width: 70px;
  height: auto; }

.responsable-flex .img-play-responsable {
  width: 180px;
  height: auto; }

.responsable-flex .img-play-responsable-and-18 {
  width: 240px;
  height: auto; }

.responsable-flex .img-play-good {
  width: 145px;
  height: auto; }

.responsable-flex .img-auto-prohibition {
  width: 75px;
  height: auto; }

@media screen and (max-width: 768px) {
  .responsable-flex .img-18-plus {
    width: 60px;
    height: auto; }
  .responsable-flex .img-play-responsable {
    width: 160px;
    height: auto; }
  .responsable-flex .img-play-responsable-and-18 {
    width: 220px;
    height: auto; }
  .responsable-flex .img-play-good {
    width: 120px;
    height: auto; }
  .responsable-flex .img-auto-prohibition {
    width: 70px;
    height: auto; } }

.gradient-bg-rooms {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%);
  background-size: 100% 70rem;
  background-repeat: no-repeat; }

.background-rooms {
  background-repeat: no-repeat;
  background-size: 100%; }

@media screen and (max-width: 768px) {
  .gradient-bg-rooms {
    background-repeat: no-repeat;
    background-size: 100% 110rem; } }

.room-title-container__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  color: #FFFFFF;
  text-transform: uppercase; }

.room-sub-title-container__title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 50px;
  line-height: 72px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

.room-page-sub-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: uppercase;
  color: #01A35A; }

@media screen and (max-width: 768px) {
  .room-title-container__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase; }
  .room-sub-title-container__title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; } }

.container-img-room-main {
  background: #222222;
  border-radius: 6px; }

.text-room-details {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  color: #80808F; }

.text-room-details-button {
  font-family: Poppins;
  width: 85%;
  max-width: 250px;
  background: #01A35A;
  border-radius: 6px;
  text-align: center !important; }

.j-habpoker-room-carousel-item {
  height: 100%; }

.green-gradient-container {
  background: linear-gradient(180deg, #296A42 -17.6%, #01A35A 40.59%, #9EE2C4 100%);
  transform: matrix(1, 0, 0, -1, 0, 0); }

.separator-room-details {
  color: #80808f;
  border-width: 2px; }

.rooms-promotions-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  padding: 1rem;
  color: #01A35A; }

@media screen and (max-width: 768px) {
  .rooms-promotions-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    text-align: center;
    padding: 1rem;
    color: #01A35A; } }

.slick-room-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #3F4254;
  padding: 1rem 0; }

.slick-room-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  color: #80808F;
  word-break: break-word; }

.slick-room-button {
  font-family: Poppins;
  width: 50%;
  max-width: 140px;
  padding: 1.1rem;
  background: #EC2F38 !important;
  border-radius: 6px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  position: absolute;
  z-index: 4;
  overflow: visible;
  transform: translateY(50%); }

@media screen and (max-width: 768px) {
  .slick-room-button {
    font-family: Poppins;
    width: 50%;
    max-width: 120px;
    padding: 1.1rem;
    background: #EC2F38 !important;
    border-radius: 6px;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    position: absolute;
    z-index: 4;
    overflow: visible;
    transform: translateY(50%); } }

.slick-list {
  overflow: inherit; }

.details-room-table {
  font-family: Poppins;
  background: #FFFFFF;
  border-radius: 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F;
  max-width: 870px; }

.img-border-round {
  border-radius: 6px; }

.post-image {
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  border-radius: 16px; }

.room-post-content img {
  max-width: 100% !important; }

.separator-party-poker {
  border: 1px solid rgba(69, 88, 128, 0.2);
  border-radius: 6px; }

.details-room-table__row {
  padding: 2rem; }

.details-rakeBack-table__row {
  padding: 1.5rem; }

.details-withdraw-table__row {
  padding: 1rem; }

.bg-details_rakeBack {
  background: #edf4f1; }

.details-room-table__divider {
  border: 1px solid rgba(0, 0, 0, 0.1); }

.rooms-step-card-header {
  height: 180px; }

.rooms-step-card-header__image {
  -o-object-position: center;
     object-position: center; }

.promotions-gradient-bg {
  margin-top: 100px;
  background: linear-gradient(360deg, #9EE2C4 0%, #01A35A 49.48%, #296A42 100%) no-repeat;
  background-size: 100% 60rem; }

.slick-promotion {
  transform: translateY(-50px); }

.promotion-card {
  background: #FFFFFF;
  border-radius: 16px;
  width: 340px;
  height: 503px;
  overflow: hidden; }

@media screen and (max-width: 768px) {
  .promotion-card {
    background: #FFFFFF;
    border-radius: 16px;
    width: 282px;
    height: 503px;
    overflow: hidden; } }

.room-promotions-container {
  width: 70%; }
  .room-promotions-container .swiper-slide {
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center; }
  .room-promotions-container .swiper-button-next,
  .room-promotions-container .swiper-button-prev {
    background-color: #222222;
    box-shadow: 0 30px 40px rgba(0, 0, 0, 0.25);
    font-size: 30px !important;
    opacity: 0.75;
    border-radius: 50%;
    height: 65px;
    width: 65px; }
  .room-promotions-container .swiper-button-next:after, .room-promotions-container .swiper-button-prev:after {
    font-size: 33px !important; }
  .room-promotions-container .swiper-button-prev:hover {
    color: white !important; }
  .room-promotions-container .swiper-button-next:hover {
    color: white !important; }
  .room-promotions-container .swiper-pagination {
    bottom: 0 !important; }

@media screen and (max-width: 768px) {
  .room-promotions-container {
    width: 100%; } }

.gradient-bg-habpoker-rooms {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute; }

@media screen and (max-width: 576px) {
  .gradient-bg-habpoker-rooms {
    background-repeat: no-repeat;
    background-size: 100% 25rem; } }

.habpoker-rooms-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

.sub-title-rooms-page {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #01A35A; }

.description-rooms-page {
  width: 65%;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #80808F; }

@media screen and (max-width: 768px) {
  .habpoker-rooms-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; }
  .sub-title-rooms-page {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #01A35A; }
  .description-rooms-page {
    width: 100%;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    color: #80808F;
    text-align: center; } }

.room-img-container {
  background: #222222;
  border-radius: 6px; }

.register-room-button {
  background: #01A35A;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF; }

.title-room-table {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  align-items: center;
  letter-spacing: 0.03em;
  color: #222222; }

.rooms-table-rooms-page .table th, .rooms-table-rooms-page .table td {
  vertical-align: middle !important; }

.rooms-table-options {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #212121; }

.container-for-select-2 .select2-container--default .select2-selection--single, .container-for-select-2 .select2-container--default .select2-selection--multiple {
  width: 176px;
  height: 24px;
  background: #F3F6F9;
  border-radius: 6px; }

.info-rooms-page {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #222222; }

@media screen and (max-width: 576px) {
  .info-rooms-page {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #222222; }
  .rooms-table-options {
    min-width: 80px; } }

.room-title-table {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F; }

.room-img-table {
  border-radius: 6px;
  width: 150px;
  height: 102px;
  -o-object-fit: fill;
     object-fit: fill;
  -o-object-position: center center;
     object-position: center center; }

@media screen and (max-width: 768px) {
  .room_details_mobile_divider {
    border: 1px solid rgba(0, 0, 0, 0.1); }
  .room-img-table {
    border-radius: 6px;
    width: 280px;
    height: 190px;
    -o-object-fit: fill;
       object-fit: fill;
    -o-object-position: center center;
       object-position: center center; }
  .rooms-title-table {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.03em;
    color: #222222; }
  .register-room-button {
    width: 50%;
    height: 40px;
    background: #01A35A;
    border-radius: 6px; }
  .rooms-name-title-table {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    color: #464E5F; } }

.news-big-content {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #80808F; }
  .news-big-content img {
    max-width: 100% !important; }

.promotion-advertisement {
  font-family: Poppins;
  font-style: oblique;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #01A35A; }

.card-img-top {
  width: 100%;
  height: 15rem;
  -o-object-fit: cover;
     object-fit: cover; }

.corporate-posts-page .gradient-bg-corporate-posts {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

@media screen and (max-width: 576px) {
  .corporate-posts-page .gradient-bg-corporate-posts {
    background-repeat: no-repeat;
    background-size: 100% 40rem; } }

.corporate-posts-page .habpoker-posts-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

.corporate-posts-page .habpoker-post-card {
  background: #FFFFFF;
  border-radius: 16px;
  width: 340px;
  overflow: hidden; }

.corporate-posts-page .post-card-img {
  width: 340px;
  height: 180px; }

@media only screen and (max-width: 769px) {
  .corporate-posts-page .habpoker-posts-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; }
  .corporate-posts-page .habpoker-post-card {
    background: #FFFFFF;
    border-radius: 16px;
    width: 283px;
    overflow: hidden; }
  .corporate-posts-page .post-card-img {
    width: 283px;
    height: 150px; } }

.corporate-posts-page .habpoker-post-details-button {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  text-align: right;
  color: #01A35A; }

.corporate-posts-page .card-post-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #464E5F; }

.corporate-posts-page .card-post-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #B5B5C3;
  word-break: break-word; }

.corporate-posts-page .pagination a {
  border-radius: 5px;
  font-size: 12px;
  margin: 0 4px; }

.corporate-posts-page .pagination a.active {
  border-radius: 5px; }

.how-to-join-page .gradient-bg-container {
  background-repeat: no-repeat;
  background-size: 100% 30rem; }

@media screen and (max-width: 576px) {
  .how-to-join-page .gradient-bg-container {
    background-repeat: no-repeat;
    background-size: 100% 40rem; } }

.how-to-join-page .how-to-join-title {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

@media screen and (max-width: 576px) {
  .how-to-join-page .how-to-join-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; } }

.how-to-join-page .how-to-step {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #222222; }

@media screen and (max-width: 576px) {
  .how-to-join-page .how-to-step {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #222222; } }

.how-to-join-page .how-to-register {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 22px;
  text-align: center;
  color: #01A35A; }

@media screen and (max-width: 576px) {
  .how-to-join-page .how-to-register {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    text-align: center;
    color: #01A35A; } }

.how-to-join-page .how-to-description-container {
  width: 600px;
  height: auto; }

@media screen and (max-width: 576px) {
  .how-to-join-page .how-to-description-container {
    width: 100%;
    height: 100%; } }

.how-to-join-page .how-to-description {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #80808F; }

@media screen and (max-width: 576px) {
  .how-to-join-page .how-to-description {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 23px;
    color: #80808F; } }

.how-to-join-page .register-button {
  width: 30%;
  background: #EC2F38 !important;
  border-radius: 6px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF; }

@media screen and (max-width: 576px) {
  .how-to-join-page .register-button {
    width: 85%;
    background: #EC2F38 !important;
    border-radius: 6px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF; } }

.how-to-join-page .gradient-bg-how-to-join {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

.what-is-habwin-page .gradient-bg-what-is-habwin {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

.terms-and-conditions-page .gradient-bg-terms-and-conditions {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

.faq-page .gradient-bg-faq {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

.cookies-page .gradient-bg-cookies {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

.contact-page .gradient-bg-contact {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

.contact-page .contact-card-header {
  background: #01A35A;
  color: #FFFFFF;
  border-bottom: 0 !important;
  padding: 2rem 2.25rem 0 2.25rem; }

.contact-page .contact-btn {
  width: 20%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF; }

@media screen and (max-width: 769px) {
  .contact-page .contact-btn {
    width: 120px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF; } }

.corporate-habstore-page .gradient-bg-corporate-habstore {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 48rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

.web-map-page .gradient-bg-web-map {
  background: linear-gradient(99.55deg, #A54BFF 0%, #1C6FEB 27.89%, #01A35A 58.25%, #9EE2C4 98.79%) no-repeat;
  background-size: 100% 35rem;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1; }

@media (min-width: 600px) {
  .habstore-banner {
    width: 80%;
    height: auto;
    align-self: center; } }

@media (max-width: 600px) {
  .habstore-banner {
    width: 100%;
    height: auto;
    align-self: center; } }

.habstore-filter-card {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: white; }
  .habstore-filter-card .title {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left; }
  .habstore-filter-card .price-filter {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left; }
  .habstore-filter-card .irs--round .irs-handle > i:first-child {
    background-color: #01A35A !important; }
  .habstore-filter-card .irs--round .irs-bar {
    background-color: #01A35A !important; }
  .habstore-filter-card .irs--round .irs-from {
    visibility: hidden !important; }
  .habstore-filter-card .irs--round .irs-to {
    visibility: hidden !important; }
  .habstore-filter-card .irs--round .irs-max {
    visibility: hidden !important; }
  .habstore-filter-card .irs--round .irs-min {
    visibility: hidden !important; }
  .habstore-filter-card .irs--round .irs-single {
    visibility: hidden !important; }
  .habstore-filter-card .irs--round .irs-handle {
    top: 27px;
    width: 20px;
    height: 20px;
    border-radius: 20px;
    background: #01A35A;
    border-color: #01A35A; }
  .habstore-filter-card .irs--round .irs-line {
    height: 4px;
    border-radius: 20px;
    background-color: #DAF4E8; }
  .habstore-filter-card .price-range {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: right;
    color: #B5B5C3; }
  .habstore-filter-card .button {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 6px;
    color: white;
    width: 91px;
    height: 42px;
    border-style: outset;
    border-bottom-width: 0;
    border-right-width: 0;
    border-color: #01A35A;
    background-color: #01A35A;
    outline: none !important; }
  .habstore-filter-card .body-title {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left; }
  .habstore-filter-card .parent-checkbox-text {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: #3F4254; }
  .habstore-filter-card .child-checkbox-text {
    font-family: Poppins;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #B5B5C3; }
  .habstore-filter-card .checkbox body {
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left; }
  .habstore-filter-card .checkbox span {
    margin-right: 10px; }

.habstore-items-card {
  border-radius: 12px; }
  .habstore-items-card .habstore-title-card {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #01A35A; }
    .habstore-items-card .habstore-title-card .title {
      font-family: Poppins;
      font-style: normal;
      color: white;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px; }
    .habstore-items-card .habstore-title-card .text {
      font-family: Poppins;
      font-style: normal;
      color: white;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px; }
    .habstore-items-card .habstore-title-card .sort-by {
      font-family: Poppins;
      font-style: normal;
      color: white;
      font-weight: 600;
      font-size: 13px;
      line-height: 19.5px; }
    .habstore-items-card .habstore-title-card .dropdown-order-by {
      border-radius: 12px;
      width: 100%;
      text-align: left; }
    .habstore-items-card .habstore-title-card .dropdown-order-by:after {
      right: -75%; }
  .habstore-items-card .item-card {
    max-height: 100%;
    min-height: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); }
    .habstore-items-card .item-card .habstore-item-image {
      width: 100%;
      height: 200px !important;
      -o-object-fit: fill;
         object-fit: fill;
      -o-object-position: center;
         object-position: center; }
    .habstore-items-card .item-card .habstore-item-header {
      height: 100%; }
    .habstore-items-card .item-card .title {
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left; }
    .habstore-items-card .item-card .price {
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left; }

.product-detail .product-description_tab img {
  max-width: 100%; }

.product-detail .product-detail-tabpane-description-title {
  font-size: 15px; }

.product-detail .product-title {
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em; }

.product-detail .dropdown-select-variation {
  border-radius: 12px;
  width: 100%;
  text-align: left; }

.product-detail .habcoin-price {
  color: #01A35A !important;
  font-size: 42px !important;
  font-weight: 700 !important; }

.product-detail .habcoin-price-habcoins {
  color: #01A35A !important;
  font-size: 37px !important;
  font-weight: 700 !important; }

.product-detail .price-euro {
  color: #888;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0; }

.product-detail .btn.btn-primary.bootstrap-touchspin-up {
  background-color: #d7dae7 !important;
  border-color: #d7dae7 !important; }

.product-detail .btn.btn-primary.bootstrap-touchspin-down {
  background-color: #d7dae7 !important;
  border-color: #d7dae7 !important; }

.product-detail .coin-euro-input {
  color: #888;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0; }

.product-detail .label-input-group {
  color: #888;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left; }

.product-detail .inputs-row {
  text-align: center;
  justify-content: space-evenly; }

.product-detail .purchase-button {
  text-align: right; }

.product-detail .swiper-container {
  max-width: 100%; }

.product-detail .swiper-image {
  max-width: 100%;
  height: auto; }

.product-detail .swiper-slide {
  margin: auto;
  display: flex;
  justify-content: center;
  align-content: center; }

.product-detail .related-products-title {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #01A35A; }

.habstore-related-products .related-products-container {
  overflow-x: hidden !important;
  overflow-y: visible !important; }

.habstore-related-products .related-products__swiper_wrapper {
  height: 320px; }

.habstore-related-products .related-products__swiper_slide {
  height: 300px; }

.habstore-related-products .related-products__swiper_pagination .swiper-pagination-bullet {
  margin-right: 10px !important; }

.habstore-related-products .related-products-title {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: 0em;
  color: #01A35A; }

.habstore-related-products .habstore-related-product-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #F3F6F9;
  cursor: pointer; }
  .habstore-related-products .habstore-related-product-card .habstore-related-product-image {
    width: 100%;
    height: 175px !important;
    -o-object-fit: fill;
       object-fit: fill;
    -o-object-position: center;
       object-position: center; }

@media screen and (min-width: 1200px) {
  .container.product-detail {
    max-width: 1140px !important; } }

@media (max-width: 767px) {
  .habstore-related-product-image {
    height: 200px !important; } }

.swiper-pagination-bullet {
  background-color: darkgrey; }

.swiper-button-prev {
  color: darkgrey; }

.swiper-button-next {
  color: darkgrey; }

.product-description {
  text-align: justify; }

.sort-by-select {
  width: 100%; }

.habstore-related-product-card {
  max-height: 100% !important;
  min-height: 100% !important; }

.habstore-related-product-card-header {
  background-color: white; }

.habstore-related-product-card-title {
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left; }

.cart-details-min-plus {
  min-width: 125px; }

@media (max-width: 768px) {
  .products-card__body {
    padding: 0 !important; }
  .products-card__row {
    padding: 15px; } }

.user-dashboard .habcoins-amount-container {
  position: relative;
  text-align: center;
  color: white; }

.user-dashboard .habcoins-amount-text {
  color: #FFA800;
  font-weight: 700;
  font-size: x-large;
  letter-spacing: 0em;
  font-family: Poppins;
  font-style: normal; }

@media screen and (min-width: 800px) {
  .user-dashboard .sticky-profile {
    position: sticky;
    top: 175px; } }

.user-dashboard .dashboard-banner {
  width: 80%;
  height: auto;
  align-self: center; }

.user-dashboard .go-pulse-button {
  font-size: 1.2em;
  border-radius: 65%;
  background-color: #daf4e8 !important;
  box-shadow: 0 0 0 0 rgba(90, 153, 212, 0.5);
  -webkit-animation: pulse 1.5s infinite;
  text-align: center;
  display: inline-flex !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 0 !important; }

.user-dashboard .go-pulse-button:hover {
  background-color: #99dcbe !important;
  -webkit-animation: none; }

.user-dashboard .go-pulse-button-icon {
  color: green;
  font-size: 1.2em; }

.user-dashboard .go-pulse-button-text {
  line-height: 65px; }

.user-dashboard .refered-user-image {
  width: 40px;
  height: auto; }

.user-dashboard .refered-user-name {
  max-width: 146px; }

.user-dashboard .product-image {
  width: 100px !important;
  max-height: 86px !important;
  -o-object-fit: fill;
     object-fit: fill;
  -o-object-position: center;
     object-position: center;
  background-color: #262626; }

.user-dashboard .register-to-rooms-card-icon {
  color: #8950FC; }

.user-dashboard .add-nick-card-icon {
  color: #FFA800; }

.user-dashboard .engage-card-icon {
  color: #009b57; }

.user-dashboard .dashboard-user-avatar {
  border-radius: 50%; }

.user-dashboard .rake-levels-table-multiplier {
  min-width: 50px; }

@media screen and (max-width: 967px) {
  .user-dashboard .flex-wrap-user-card-dashboard {
    flex-wrap: wrap; }
  .user-dashboard .aside-card-user-details-mobile {
    width: 100%; } }

@media screen and (min-width: 967px) {
  .user-dashboard .aside-card-user-details {
    position: sticky;
    top: 80px;
    z-index: 1020; }
  .user-dashboard .offcanvas-mobile-user-card-dashboard {
    width: 100px; } }

@media screen and (min-width: 967px) {
  .user-dashboard .offcanvas-mobile-user-card-dashboard-xl {
    width: 325px !important; } }

@media screen and (max-width: 967px) {
  .user-dashboard .steps-arrow {
    transform: rotate(90deg) translateX(-10px); } }

.user-dashboard .best-rooms-empty-text {
  font-weight: 500;
  font-size: large;
  letter-spacing: 0em;
  font-family: Poppins;
  font-style: normal;
  text-align: center; }

.user-dashboard .invite-friends-pulsating-button {
  font-size: 16px; }

.user-dashboard .refereds-empty-card {
  background-position: right top;
  background-size: 30% auto;
  background-image: url(/build/assets/media/shared/icons/abstract-3.svg); }

.my-rakes {
  box-shadow: none !important; }
  .my-rakes .my-rakes-user-image {
    height: 100px;
    width: 100%; }
  .my-rakes .statistics-card-toolbar {
    border-bottom: none; }
  .my-rakes .statistics-card-body {
    position: relative; }
  .my-rakes .statistics-room-graph-card {
    box-shadow: none; }
  .my-rakes .habcoins-icon {
    color: #FFA800;
    font-size: 20px; }
  .my-rakes .highest-habcoins {
    color: #FFA800;
    font-size: 20px; }
  .my-rakes .exclamation-icon {
    color: #ee5232;
    font-size: 20px; }
  .my-rakes .highest-rake-icon {
    color: #509c57;
    font-size: 20px; }
  .my-rakes .email-icon {
    color: #927efb;
    font-size: 20px; }
  .my-rakes .user-icon {
    color: #c4c4c5;
    font-size: 20px; }
  .my-rakes .room-charts {
    min-height: 365px; }
  .my-rakes .total-habcoins-pie-chart-card {
    box-shadow: none; }
  .my-rakes .total-habcoins-pie-chart {
    min-height: 265.7px; }
  .my-rakes .room-name-row {
    cursor: pointer; }
  @media (max-width: 576px) {
    .my-rakes .user-details-info {
      flex-direction: row !important;
      align-items: center !important;
      justify-content: space-between !important; }
    .my-rakes .user-details-spacer {
      display: none; }
    .my-rakes .my-statistics-card-header {
      padding: 0; }
    .my-rakes .room-statistics {
      padding-bottom: 20px !important; } }
  .my-rakes .rakes-table-row-created-at {
    min-width: 70px; }
  .my-rakes .coffee-svg__tab-pane {
    padding: 0 !important; }
  .my-rakes .user-details_span {
    max-width: 100%; }

.my-rooms .steps-cards {
  height: 100%; }

.my-rooms .rooms-nav-links {
  font-size: 2rem;
  font-weight: bold;
  padding: 1rem 1rem 0 1rem !important; }

.my-rooms .habpoker-register-link {
  color: blue;
  cursor: pointer; }

.my-rooms .habpoker-table-header {
  text-align: center;
  font-weight: 600;
  font-size: 1.6rem;
  color: gray; }

.my-rooms .habpoker-table-row-text {
  text-align: center;
  vertical-align: middle;
  border: none; }

.my-rooms .habpoker-table-row {
  border: none;
  vertical-align: middle !important; }

.my-rooms .habpoker-intructions-column {
  font-size: 1.5rem;
  cursor: pointer;
  width: 50px;
  height: 40px;
  text-align: center;
  line-height: 20px; }

.my-rooms .habpoker-table {
  border-collapse: separate;
  border-spacing: 0 20px; }

.my-rooms .check-verify-circle-icon {
  font-size: 1.5rem; }

.my-rooms .check-verify-button {
  cursor: inherit !important; }

.my-rooms .verify-circle-icon {
  font-size: 1.5rem; }

.my-rooms .clock-pending-icon {
  font-size: 1.5rem; }

.my-rooms .clock-pending-button {
  cursor: inherit !important; }

@media screen and (max-width: 767px) {
  .my-rooms .steps-arrow {
    transform: rotate(90deg) translateX(-10px); }
  .my-rooms .table-row-remove__button {
    display: none; } }

.my-rooms .navs-card__body {
  padding: 0; }

.my-rooms .table-row-input {
  max-width: 175px !important;
  min-width: 150px !important; }

@media (max-width: 576px) {
  .select2-form-group.select2-container {
    width: 50% !important; } }

.affiliate-habcoin-withdraw {
  font-size: 1.5rem !important; }

.send-request-button-icon {
  font-size: 1.2rem; }

.wallet-movements-card-title {
  font-size: 1.6rem !important; }

.avaliable-habcoins-card:hover .avaliable-habcoins-card-hover {
  color: white !important; }

.avaliable-habcoins-title {
  align-self: end;
  color: #FFA800; }

.avaliable-habcoins-icon {
  color: #FFA800; }

.year-balance-icon {
  color: #8950FC; }

.month-balance-icon {
  color: #1BC5BD; }

.modal-withdraw-types {
  width: 600px; }

.flag-info-ribbon:before {
  position: relative;
  top: -10px;
  color: white; }

.cart-header-scroll {
  max-height: 375px; }

.product-count-button {
  cursor: default !important; }

.wallet-habcoins__topbar_item_label {
  cursor: pointer !important; }

.wallet-habcoins__topbar_item_button {
  color: #ffffff !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: transparent !important; }

.wallet-habcoins__topbar_item_button:hover {
  background-color: rgba(255, 255, 255, 0.2) !important; }

.impersonator-back-to-admin {
  color: #ffffff !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-color: transparent !important; }

.room-links .url-table__column {
  min-width: 200px !important; }

@media (max-width: 576px) {
  .sales-dashboard .revenue-chart__card-body {
    padding: 0 !important; }
  .sales-dashboard .revenue-per-room-chart__card-body {
    padding: 0 !important; } }

.progress-bar-custom {
  margin: 30px 0px;
  width: 400px;
  height: 3px;
  position: relative; }

.label.rakeback-range-legend {
  font-size: 0.9rem !important; }

.room-reports-content {
  background-position: right top;
  background-size: 30% auto;
  background-image: url(assets/media/svg/shapes/abstract-2.svg); }

.room-multiplier {
  width: 150px !important;
  align-items: center; }

.applied-formula {
  background-color: #ffeb93;
  font-size: large;
  height: auto; }

.start-vertical-separator {
  border-left: 1px solid lightgrey;
  height: 160px; }

.table-row-select-user-credentials {
  position: relative;
  background-color: #4CAF50;
  border: none;
  color: #FFFFFF;
  text-align: center;
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer; }

.table-row-select-user-credentials:after {
  content: "";
  background: #90EE90;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s; }

.table-row-select-user-credentials:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s; }

.fav {
  color: lightgreen; }

.product-images-uploaded {
  max-height: 180px !important;
  max-width: 100%; }

.add-new-RakebackRange {
  background-color: var(--primary); }

.remove-RakebackRange {
  color: white;
  background-color: var(--danger); }

.remove-RakebackRange .flaticon2-cross {
  color: white; }

.form-remove-button .flex-spacer .body {
  background-color: black !important;
  color: white !important; }

.room-image-upload {
  max-height: 180px !important;
  max-width: 100%; }

.affiliate-details .room-commission-form-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 450px; }

.affiliate-details .user-room-credentials-form-container {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 450px; }

.affiliate-details .credentials-modal-dialog {
  min-width: 1250px !important; }

room-post-extended .j-select-2 {
  width: 600px !important; }

@media print {
  .flex-root .header, .header-mobile, .cards-background, .footer {
    display: none !important; }
  .main-page-background {
    background-color: white !important; }
  .card-body .bg-gray-100 {
    background-color: white !important; } }

.blog-room-post-details #esContent .j-post-content-shortDescription-form, .blog-room-post-details #esContent .j-post-content-content-form {
  display: none; }

.blog-room-post-details #esEsContent .j-post-content-shortDescription-form, .blog-room-post-details #esEsContent .j-post-content-content-form {
  display: none; }

.blog-room-post-details #ukEsContent .j-post-content-shortDescription-form, .blog-room-post-details #ukEsContent .j-post-content-content-form {
  display: none; }

.blog-room-post-details #ukEnContent .j-post-content-shortDescription-form, .blog-room-post-details #ukEnContent .j-post-content-content-form {
  display: none; }

.blog-room-post-details #enContent .j-post-content-shortDescription-form, .blog-room-post-details #enContent .j-post-content-content-form {
  display: none; }

.content-editor-nav-error {
  border-bottom: 3px solid red !important; }

.banner-card-dimensions {
  width: 200px;
  height: 300px; }

.banner-image-dimension {
  height: 116px;
  width: 100px; }

.rakes-form .rakebacks__select {
  min-width: 200px !important; }

.rakes-form .select2-container {
  min-width: 250px !important; }

.charge-rakes_room-select {
  width: 100% !important; }

.charge-rakes_btn-reset {
  font-size: large; }

.btn.btn-primary.charge-rakes_btn-charge {
  font-size: large !important; }

.hover-text-white:hover .hover-text-white-children {
  color: white !important; }

.table-expand-refereds_button {
  color: #67ae4d; }

.table-expand-refereds_button:hover {
  color: #aad337; }

.table-expand-subs_button {
  color: #2b8ab1; }

.table-expand-subs_button:hover {
  color: #56bbaa; }

.row-expanded_common {
  box-shadow: 0px 2px 13px -3px rgba(0, 0, 0, 0.43);
  position: relative !important; }

.row-expanded_1 {
  z-index: 10; }

.row-expanded_2 {
  z-index: 9; }

.row-expanded_3 {
  z-index: 8; }

.row-expanded_4 {
  z-index: 7; }

.row-expanded_5 {
  z-index: 6; }

.row-expanded-refered-parent {
  background-color: #daf4e8;
  line-height: 40px !important;
  font-weight: bold; }

.row-expanded-refered-parent--as-player {
  background-color: #fff4de;
  line-height: 40px !important;
  font-weight: 500; }

.row-expanded-refered-parent--from-credentials-without-user {
  background-color: #fff4de;
  line-height: 40px !important;
  font-weight: 500; }

.row-expanded-refered-parent--as-affiliate {
  background-color: #fff4de;
  line-height: 40px !important;
  font-weight: 500; }

.row-expanded-refered-parent--from-refereds {
  background-color: #fff4de;
  line-height: 40px !important;
  font-weight: 500; }

.row-expanded-refered-children_1 {
  background-color: #fcf9bc;
  transform: translateX(10px); }

.row-expanded-refered-children_2 {
  background-color: #eafcba;
  transform: translateX(20px); }

.row-expanded-refered-children_3 {
  background-color: #cdfcbd;
  transform: translateX(30px); }

.row-expanded-refered-children_4 {
  background-color: #bbfcd3;
  transform: translateX(40px); }

.row-expanded-refered-children_5 {
  background-color: #c1fce9;
  transform: translateX(50px); }

.row-expanded-subaffiliate-children_1 {
  background-color: #53e78c;
  transform: translateX(10px); }

.row-expanded-subaffiliate-children_2 {
  background-color: #00baaa;
  transform: translateX(20px); }

.row-expanded-subaffiliate-children_3 {
  background-color: #0089b1;
  transform: translateX(30px); }

.row-expanded-subaffiliate-children_4 {
  background-color: #005798;
  transform: translateX(40px); }

.row-expanded-subaffiliate-children_5 {
  background-color: #022561;
  transform: translateX(50px); }

.load-mask {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 9999999;
  background-color: white; }

.main-page-background {
  background-color: #EEF4F1; }

.banner {
  margin-top: 0; }
  .banner .swiper-container {
    width: 70%;
    border-radius: 12px; }

.banner__image {
  border-radius: 12px;
  width: 100%;
  max-height: 250px; }

@media screen and (max-width: 768px) {
  .banner .swiper-container {
    width: 95%; }
  .banner__image {
    max-height: 150px; } }

@media screen and (max-width: 450px) {
  .banner .swiper-container {
    width: 92.5%; }
  .banner__image {
    max-height: 75px; } }

.cards-home-background .cards-background__left-cards {
  position: absolute;
  left: 0;
  bottom: 0; }

.cards-home-background .cards-background__right-cards {
  position: absolute;
  right: 0;
  bottom: 0; }

.cards-background__left-cards {
  position: absolute;
  left: 0;
  bottom: 10%; }

.cards-background__right-cards {
  position: absolute;
  right: 0;
  bottom: 10%; }

.ageModal {
  background: #ffffff75;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); }

.responsability-warning {
  position: fixed;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
  background: black;
  width: 250px;
  height: auto;
  z-index: 999999; }

.responsability-warning-18 {
  position: fixed;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-425%);
  height: 40px;
  width: auto;
  z-index: 999999; }

.cc-color-override-1219479847.cc-window {
  z-index: 9999999999999999999; }

.post-content-content .note-editor.note-frame .note-editing-area .note-editable, .post-content-content .note-editor.note-airframe .note-editing-area .note-editable, .page-section-variation-content .note-editor.note-frame .note-editing-area .note-editable, .page-section-variation-content .note-editor.note-airframe .note-editing-area .note-editable {
  background-color: white; }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #009b57;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #009b57 transparent transparent transparent; }

.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s; }

.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s; }

.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s; }

@-webkit-keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

@keyframes lds-ring {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.swiper-slide__img {
  width: 100%;
  display: block;
  height: 100%; }

.background-image {
  width: 100%;
  height: 100%;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: left !important; }

.blank-card {
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  width: 100%;
  overflow: hidden; }

.black-card {
  background: #222222;
  border-radius: 16px;
  width: 100%;
  overflow: hidden; }

.card-header {
  border-bottom: 0 !important;
  padding: 2rem 2.25rem 0 2.25rem; }

.bg-black {
  background-color: #222222 !important; }

.card-subheader {
  display: block;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: #B5B5C3; }

.card-label {
  color: #01A35A;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px; }

.nav-link {
  font-family: Poppins;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 18px; }

.btn.btn-primary {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 18px !important;
  text-align: center !important;
  background: #01A35A !important;
  border-color: #01A35A !important;
  border-radius: 6px !important; }

.scrolltop {
  background-color: #01A35A !important; }

.overflowx-hidden {
  overflow-x: hidden; }

.a-hover-color-force:hover {
  color: #015730 !important; }

.slick-next:before {
  color: #FF8A00; }

.slick-prev:before {
  color: #FF8A00; }

.slick-slide {
  outline: none;
  height: inherit !important; }

.fa-1-5x {
  font-size: 1.5em !important; }

.d-flex-custom {
  display: flex; }

.slick-track {
  display: flex !important; }

.send-request-button {
  cursor: pointer; }

.error.error-6 .error-title {
  font-size: 5rem !important; }

@media (min-width: 768px) {
  .error.error-6 .error-title {
    font-size: 10rem !important; } }

.z-index-front {
  z-index: 9999; }

.bg-primary {
  background-color: #009b57 !important; }

.ps-pnf-iframe-wrapper {
  display: none !important; }
